import React from 'react';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import MessageModal from '../../components/SharedComponents/MessageModal';
import IconTextField from '../../components/SharedComponents/IconTextField/IconTextField';

export default class Field5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkboxValue: true,
            isOpen: false
        }
    }
    render() {
        return (
            <div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicCheckbox
                        label="Label"
                        checked={this.state.checkboxValue}
                        onChange={(e) => this.setState({ checkboxValue: e.target.checked })}
                        align="top"
                        id="talent-address-id-0" />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicCheckbox
                        label="Label"
                        checked={this.state.checkboxValue}
                        onChange={(e) => this.setState({ checkboxValue: e.target.checked })}
                        align="start" 
                        id="talent-email-id-0"/>
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicCheckbox
                        label="Label"
                        checked={this.state.checkboxValue}
                        onChange={(e) => this.setState({ checkboxValue: e.target.checked })}
                        align="bottom" 
                        id="talent-email-id-1"/>
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicCheckbox
                        label="Label"
                        checked={this.state.checkboxValue}
                        onChange={(e) => this.setState({ checkboxValue: e.target.checked })}
                        align="end" 
                        id="talent-phone-id-0"/>
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicCheckbox
                        label="Disabled"
                        checked={this.state.checkboxValue}
                        disabled={true}
                        onChange={(e) => this.setState({ checkboxValue: e.target.checked })}
                        align="start"
                        id="talent-phone-id-1" />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicCheckbox
                        label="Check me to open modal!"
                        checked={this.state.isOpen}
                        onChange={(e) => this.setState({ isOpen: !this.state.isOpen })}
                        align="start" 
                        id="talent-address-id-1"/>
                    <MessageModal
                        open={this.state.isOpen}
                        title={"Unsaved Changes"}
                        message={"Would you like to save your changes before navigating away?"}
                        primaryButtonText={"Save & Exit"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => alert("Button Clicked")}
                        handleClose={(e) => this.setState({ isOpen: false })} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <IconTextField icon="search"/>
                </div>
                {/* <div>
                <span>
                <input type="checkbox" id="test1" />
	            <label for="test1" aria-describedby="label">Red</label>
                </span>
                </div> */}
            </div>
        )
    }
}
