import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import BasicButton from '../SharedComponents/BasicButton/BasicButton'
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel'
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField'
import SearchSelectField from '../SharedComponents/SearchSelectField'
import { validateCharNumOnlyWithSpace } from "../../Common/Helper"
import Messages from "../../Common/Messages.json"
import sizeLimit from "../../Common/SizeLimits.json"
import { CircularProgress } from '@material-ui/core';
import { GroupDetails, ResourcesData } from './Config'
import SchedulerService from '../../services/service';
import * as Constants from '../../constants/constants';
import './Groups.scss'
import { withUserContext } from '../../contexts/UserContext'
export default withUserContext(class AddEditGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postInitiated: false,
            showLoader:false,
            groupDetails: GroupDetails,
            resourcesData: [],
            selectedValue: {},
            removedResource:[]

        }
    }
     componentDidMount() {
        
        if(this.props?.mode === "Edit"){
            this.getGroupData();
        }
        else {
             this.getResourceData();
        }       
         
            
    }
    handleChange = (field, value) => {
        this.setState(prevState => ({
            groupDetails: {
                ...prevState.groupDetails,
                [field]: value || null
            }
        }))
    }
    checkFieldLength = (field, limit) => {
        return field ? field.length <= limit : true
    }
    validateBeforSubmit(postData) {
        return postData?.group_name && this.checkFieldLength(postData?.resource_name, sizeLimit.nameCharacterLimit) &&
            postData?.resources.length > 0

    }
  
    getResourceData() {    
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl +"/resource?resourceId=null",this.props?.userContext?.active_tenant?.tenant_id)
        .then((response)=>{
            console.log(response)
            let data = response.data;
            if(this.props?.mode === "Edit"){
                data = data?.filter(item =>  this.state?.groupDetails.resources?.findIndex(i=> i.resource_id == item?.resource_id) == -1);
            }
            let formattedList = data?.map(item => ({ value: item.resource_id, text: item.resource_name }))
            this.setState({ resourcesData: formattedList })
         
        },err=>{
            console.log(err)         
        })
       
    }
    getGroupData(){
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl +"/group?groupId="+this.props?.id,this.props?.userContext?.active_tenant?.tenant_id)
        .then((response)=>{
            if(response?.data?.error){
                console.log(response)
            }
            let data = response?.data?.[0];
            data.resources.map((o)=>{
                 o.group_id=this.props?.id
                 o.is_delete=0
                 return o
            })
            this.setState({groupDetails:{...GroupDetails,...response?.data?.[0]}})
            this.getResourceData();
         
        },err=>{
            this.props.fieldChanged(true, "fail");            
        })
    }
    postGroup() {
        this.setState({ postInitiated: true,showLoader:true })
        let postData = { ...this.state?.groupDetails }
        if (!this.validateBeforSubmit(postData)) {
            this.setState({ showLoader: false })
            return;
        }
        if(this.props?.mode ==="Edit" && this.state?.removedResource?.length > 0){
            let removed=this.state?.removedResource.filter(e=>e?.group_resource_id);
            postData.resources = [...postData.resources,...removed]
        }
        SchedulerService.postDataParams(`${Constants.SchedulerServiceBaseUrl}/group`, postData,this.props?.userContext?.active_tenant?.tenant_id)
        .then((response) => {
            this.setState({ postInitiated: false, showLoader: false });
            if(response?.data?.error_code || response.data.error){
                this.props.fieldChanged(true, "fail",response?.data?.error_code ? response?.data?.error_message : undefined);
                return;
            }     
            // console.log(response);
            this.props.fieldChanged(true, "success");
            this.props?.setCallRefresh("Group",true)
            // this.props.toggleRefreshPage("Group");
        }, (err) => {
            this.props.fieldChanged(true, "fail");
            this.setState({ postInitiated: false , showLoader: false});
        })
    }
    addResource() {
        let val = this.state.selectedValue;
        if (val?.value && val?.text) {
            let resourcesFilterData = this.state?.resourcesData?.filter(item => item.value != val.value);
            let obj={
                resource_id: val?.value, 
                resource_name: val?.text, 
                group_resource_id :null,
                is_delete:0,
                group_id: this.props?.mode === "New" ? null: this.props?.id 
            }
            let resourceAdded = [...this.state?.groupDetails?.resources, obj]
            this.handleChange('resources', resourceAdded);
            this.setState({ selectedValue: null, resourcesData: resourcesFilterData })
        }
    }
    removeResource(obj) {
        if(obj){            
            let resourceAdded = [...this.state?.resourcesData, { value:obj?.resource_id, text:obj?.resource_name, group_resource_id :null,
                group_id: this.props?.mode === "New" ? null: this.props?.id   }]
            let resourceRemoved = this.state?.groupDetails?.resources?.filter(item => item.resource_id != obj.resource_id);
            let removedObj = [...this.state?.removedResource,{...obj,is_delete:1}]
            this.handleChange('resources', resourceRemoved);
            this.setState({resourcesData:resourceAdded,removedResource:removedObj})
        }
    }
    render() {
        return (
            <MDBContainer fluid className='mt-2 AddEditGroup'>
                <MDBRow>
                    <MDBCol md={8}>
                        <BasicTextField
                            id={"groupName"}
                            label={"Group Name"}
                            limit={sizeLimit.nameCharacterLimit}
                            limitWarning={Messages.exceed50CharacterWarning}
                            value={this.state?.groupDetails?.group_name}
                            onChange={(e) => validateCharNumOnlyWithSpace(e.target.value) ? this.handleChange('group_name', e.target.value) : null}
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={12}>
                        <BasicLabel isMandatory={true} text={'Resources'} />
                    </MDBCol>
                </MDBRow>
                <div className='resourcesWrapper'>
                    {this.state?.groupDetails?.resources.map(item => (
                        <div className='resourceEle flex' key={item?.resource_id}>
                            <span>{item?.resource_name}</span>
                            <MDBIcon fas icon="trash-alt" size={15} onClick={this.removeResource.bind(this, item)}></MDBIcon>
                        </div>
                    ))
                    }
                    <div className='resourceEle flex'>
                        <SearchSelectField
                            id={'selectResource'}
                            value={this.state?.selectedValue || {}}
                            options={this.state?.resourcesData || []}
                            onChange={(e, value) => this.setState({ selectedValue: value })}
                        />
                        <MDBIcon fas icon="plus-circle" size={20} onClick={this.addResource.bind(this)}></MDBIcon>
                    </div>

                </div>
                { (this.state?.postInitiated && this.state?.groupDetails?.resources?.length == 0) &&  
                    <div className='errorText'>Resource is required!</div>
                }
                <div className="modalFooterBtn flex">
                    <BasicButton
                        variant='outlined'
                        text="Cancel"
                        type="inline"
                        onClick={this.props?.onClose}


                    />
                    <BasicButton
                        text={this.state?.showLoader ? <CircularProgress color="inherit" size={20} /> : this.props?.mode == "New" ? "Create": "Save"}
                        type="inline"
                        disabled={!this.props?.userContext?.active_tenant?.permissions?.canAddGroups || !this.props?.userContext?.active_tenant?.permissions?.canEditGroups}
                        onClick={this.postGroup.bind(this)}
                    />
                </div>
            </MDBContainer>
        )
    }
});
