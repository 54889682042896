import React from 'react';;
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import Field1 from './Field1';
import Field2 from './Field2';
import Field3 from './Field3';
import Field4 from './Field4';
import Field5 from './Field5';

class TestBasicComp extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <>
                <MDBCard>
                    <MDBCardBody>
                        <Field1 />
                        <Field2 />
                        <Field3 />
                        <Field4 />
                        <Field5 />
                    </MDBCardBody>
                </MDBCard>
            </>
        );

    }

}

export default TestBasicComp;