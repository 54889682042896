import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from './BasicTextField/BasicTextField';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';

class TableFilters extends React.Component {
  constructor(props) {
    super(props);    
  }

  pickFilters(inputType, name){
   
    switch(inputType) {
        case 'text':
        case 'textarea':
        case 'select':
          return <BasicTextField id={name} 
                    value={this.props.inputValue}
                    onKeyUp={(event) => {                      
                      if (event.keyCode === 13){
                        this.props.addFilterChangeHandler(event.target.value);
                      }
                      
                    }}         
                    onChange={(e) => this.props.filterChangeHandler(e.target.value)}          
                    />;
          break;
        case 'checkbox':
            return <BasicCheckbox id={name}
                      onChange={(e) => this.props.addFilterChangeHandler((e.target.checked ? "1": "0"))}                          
                        />;
            break;
        // case 'select':
        //   return <SelectField
        //               id={name}
        //               size="small"
        //               label={name}
        //               value={""}
        //               options={[]}
        //               onChange={(e) => this.props.addFilterChangeHandler(e.target.value)}                          
        //                           />;                        
        default:
          return <></>;
      }

  }


  render() {
    return (
    <>
    {this.pickFilters(this.props.inputType, this.props.name)}
    </>) 
  }
}

export default TableFilters;