import React,{useEffect} from "react";
import { MDBTooltip } from 'mdb-react-ui-kit';
function resourceGroupLabelContent(info) {
    // console.log(info)
  
    return (
      <div key={info?.id} style={{backgroundColor:info?.eventBackgroundColor}}>
       <MDBTooltip tag='div' placement="right" wrapperClass="tootlipWrapper"  title={
        <>
          <div>{info?.resource?.title}</div>
          <span>{info?.resource?.extendedProps?.technology}</span>
        </>
      }>       
        <span className="resourceName">{info?.resource?.title}</span>
      </MDBTooltip>
      </div>
    )
  }

  export default resourceGroupLabelContent;