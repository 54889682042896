import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import "@fortawesome/fontawesome-free/css/all.min.css";
//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import 'mdb-react-ui-kit/dist/css/mdb.min.css';
//import "./assets/scss/_custom-variables.scss";
import './fonts/Monoton-Regular.ttf';
import './fonts/WarnerBrosSans-Regular.ttf';
import './fonts/WarnerBrosSans-Bold.ttf';
import './fonts/WarnerBrosSans-Heavy.ttf';
import "./assets/scss/mdb.pro.scss";
import "./index.scss";
import App from "./home/App";
import ErrorBoundary from "./error-boundary/ErrorBoundary";
//import { Provider } from 'react-redux';
//import {store} from './redux/store';

ReactDOM.render(
    // <Provider store={store}>
    <ErrorBoundary>
        <Router>
            <App />
        </Router>
    </ErrorBoundary>
    // </Provider>
    ,
    document.getElementById('root'));
