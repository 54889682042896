import React from 'react';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import TwoPartTextField from '../../components/SharedComponents/TwoPartTextField/TwoPartTextField';

export default class Field1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicFieldInput: "Basic Field",
            waterMarkInput: "",
            disabledText: "Show Name",
            multiLineInput: "",
            twoPartValue1: "twoPartValue1",
            twoPartValue2: "twoPartValue2"
        }
    }
    render() {
        return (
            <div>
                <div style={{ paddingBottom: '20px'}}>
                    <BasicTextField id={"key1"}
                        value={this.state.basicFieldInput}
                        onChange={(e) => this.setState({ basicFieldInput: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <BasicTextField id={"key2"}
                        value={this.state.waterMarkInput}
                        label="Field Name"
                        placeholder={"Watermark Text"}
                        onChange={(e) => this.setState({ waterMarkInput: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <BasicTextField id={"key3"}
                        value={this.state.disabledText}
                        label="Field Name"
                        isMandatory={true}
                        disabled={true}
                        onChange={(e) => this.setState({ disabledText: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <BasicTextArea id={"key4"}
                        value={this.state.multiLineInput}
                        label={"Field Name"}
                        onChange={(e) => this.setState({ multiLineInput: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <BasicTextArea id={"key4"}
                        value={this.state.multiLineInput}
                        placeholder={"Watermark Text"}
                        onChange={(e) => this.setState({ multiLineInput: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <BasicTextArea id={"key4"}
                        value={this.state.multiLineInput}
                        disabled={true}
                        onChange={(e) => this.setState({ multiLineInput: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <TwoPartTextField id={"key5"} 
                        value1={this.state.twoPartValue1}
                        value2={this.state.twoPartValue2}
                        field1Length={3}
                        field2Length={9}
                        label={"Field Name"}
                        isMandatory={true}
                        onChangeOfValue1={(e) => this.setState({ twoPartValue1: e.target.value })}
                        onChangeOfValue2={(e) => this.setState({ twoPartValue2: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <TwoPartTextField id={"key6"} 
                        value1={this.state.twoPartValue1}
                        value2={this.state.twoPartValue2}
                        placeholder1={"Watermark Text1"}
                        placeholder2={"Watermark Text2"}
                        onChangeOfValue1={(e) => this.setState({ twoPartValue1: e.target.value })}
                        onChangeOfValue2={(e) => this.setState({ twoPartValue2: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px'}}>
                    <TwoPartTextField id={"key7"} 
                        value1={this.state.twoPartValue1}
                        value2={this.state.twoPartValue2}
                        isField1Disabled={true}
                        isField2Disabled={true}
                        onChangeOfValue1={(e) => this.setState({ twoPartValue1: e.target.value })}
                        onChangeOfValue2={(e) => this.setState({ twoPartValue2: e.target.value })} />
                </div>
            </div>
        )
    }
}
