import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import {
  
    MDBContainer
} from "mdb-react-ui-kit";
import {
    BrowserRouter as Router
} from "react-router-dom";
import Routes from '../route/Routes';
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import './MainContent.scss';
import MainViewModalComponent from '../Common/MainViewModalLayout/Modal/Modal';
import * as Constants from '../constants/constants';
import ScripterService from '../services/service';
import { withUserContext } from '../contexts/UserContext';

export default withUserContext(class MainContent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
           
          
            user_name: localStorage.getItem("user_name"),
            activeTab: "",
            active_route: "",
            showScheduleMeetingPopup:false,
            meeting_id:null,  
            meeting_days_id:null,
            editType:null,         
            isManageUser: false,
            refreshPage: { "modal": '', "value": false },
           
        }
        this.mainContent = React.createRef();
    }


    toggleRefreshPage = (modalName) => {
        let refreshPage = {
            "modal": modalName,
            "value": !this.state.refreshPage?.value
        }
        this.setState({ refreshPage: refreshPage })
    }

  

    setActiveTab = (value) => {
        this.setState({ activeTab: value });
    }

    handleSideNavActive = (value, routePath) => {
        this.setActiveTab(value);
        this.props.history.push(routePath, this.state);
    }

    switchTenant = (tenant_id) => {
        this.props.switchTenant(tenant_id);
    }

    functions = {   
        handleShowScheduleMeetingPopup:()=>{
            this.setState({showScheduleMeetingPopup:true,meeting_id:null,editType:null})
        },
        handleShowScheduleMeetingEditPopup:(id=null,meetingDaysId=null,type=null)=>{
            this.setState({showScheduleMeetingPopup:true,meeting_id:id,meeting_days_id:meetingDaysId,editType:type})
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.active_route !== this.props.active_route) {
            this.props.history.push(this.props.active_route, this.state);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {
            this.setState({
                active_route: nextProps.active_route,
            });

        }
    }



    render() {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        return (

            <>
                <div>
                    {/* <HeaderContent {...this.props} {...this.state} 
                  authState={this.props.authState} 
                  switchTenant = {this.switchTenant.bind(this)}
                  setActiveTab = {this.setActiveTab.bind(this)} /> */}

                    <MDBContainer fluid >
                        <MDBRow className="mainContent">
                           
                            <MDBCol className="w-auto main-content" id="main-content">
                                <div ref={this.mainContent} >
                                    <ErrorBoundary>
                                        <Routes authState={this.props.authState} {...this.props} {...this.state}
                                            refreshPage={this.props?.refreshPage}
                                            toggleRefreshPage={this.props?.toggleRefreshPage}
                                            functions={this.functions}
                                            handleSideNavActive={this.handleSideNavActive.bind(this)}
                                            setActiveTab={this.setActiveTab.bind(this)} />
                                    </ErrorBoundary>
                                </div>
                            </MDBCol >
                            <MDBCol className="col-qtr" >

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <div className="modals">
                        {this.state.showScheduleMeetingPopup &&
                            <MainViewModalComponent
                                open={this.state.showScheduleMeetingPopup}
                                handleClose={() => this.setState({ showScheduleMeetingPopup: false })}
                                headerText={"Meeting"}
                                mode={this.state?.meeting_id ? "Edit":"New"}
                                modalName={"Schedule Meeting"}
                                id={this.state?.meeting_id || null}
                                meetingDaysId={this.state?.meeting_days_id || null}
                                editType={this.state?.editType || null}
                                refreshPage={this.props?.refreshPage}
                                toggleRefreshPage={this.props?.toggleRefreshPage}
                           
                            />
                        }
                     
                       
                    </div>
                </div>
            </>

        );
    }
});

// export default MainContent;