export const talentNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Contact",
        index: 1
    },
    {
        label: "Representation",
        index: 2
    },
    {
        label: "Acting History",
        index: 3
    },
    {
        label: "Audition History",
        index: 4
    },
    {
        label: "Files",
        index: 5
    }
]

export const agentNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Agent List",
        index: 1
    },
    {
        label: "Covered Talent",
        index: 2
    }
]

export const productionCompanyList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Contacts",
        index: 1
    },
    {
        label: "Features",
        index: 2
    }
]


export const crewList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Contacts",
        index: 1
    },
    {
        label: "Production",
        index: 2
    }
]

export const representativeNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Represented Talent",
        index: 1
    }
]