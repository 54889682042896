import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../BasicTextField/BasicTextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BasicLabel from '../BasicLabel/BasicLabel';
import BasicCheckbox from '../BasicCheckbox/BasicCheckbox';
import './ListField.scss';

export default class ListField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <MDBRow className="ListFieldsContainer">

                {this.props?.label && <MDBRow> <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} /></MDBRow>}

                <MDBRow>
                    <MDBCol md={8}>
                        <BasicTextField id={this.props?.id}
                            value={this.props?.value || ""}
                            onChange={this.props.onChange}
                            disabled={this.props?.disabled || false}
                            limitWarning={this.props.limitWarning || ""}
                            limit={this.props.limit}
                            showAsLabel={this.props.disabled || false}
                            placeholder={this.props?.placeholder || ""} />
                    </MDBCol>
                    {
                        !this.props.hidePrimary &&
                        <MDBCol md={2}>
                            <BasicCheckbox
                                label="primary"
                                checked={this.props?.isPrimary || false}
                                onChange={(e) => this.onChangeCallback("is_primary", e.target.value)}
                                align="start"
                                id={this.props.id}
                            />
                        </MDBCol>
                    }
                    <MDBCol md={2} className="actions">
                        {
                            (this.props?.showRemove && !this.props?.disabled) ? <RemoveCircleIcon onClick={this.props?.removeCallback} /> : null
                        }
                        {
                            (this.props?.showAdd) ? <AddCircleIcon onClick={this.props?.addCallback} /> : null
                        }
                    </MDBCol>
                </MDBRow>
            </MDBRow>
        );
    }
}

