export const ScheduleMeetingData={
    schedule_meeting_id:null, // use to delete or update whole occurrence
    schedule_meeting_days_id:null, // use to delete or update single meeting in occurrence
    date:null,
    start_date:null,
    end_date:null,
    start_time:null,
    end_time:null,
    subject:null,
    resource_id:null,
    resource_name:null,
    notes:null,
    is_recurring:false,
    recurrence_interval:'DAILY',
    recurrence_interval_value:1,
    recurrence_range:'YEAR',
    recurrence_range_value:1,
    after_occurrence:null,
    is_delete:0,   
    is_occurence_delete:0
}