export const ResourceTableConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Room Name",
            width: "45%",
            isColor:true,
            dataNodes:'resource_name',
            colorNode :'color',
            icon:'sort-alpha-down'
           
        },
        {
            headingLabel: "Technology",
            width: "45%",
            dataNodes:'technology'
           
        },
        {
            headingLabel: "",
            width: "10%"
           
        }
       
    ],
    hyperlinks:["resource_name"],
    dataNodes: ["resource_name", "technology"],
    primaryKey: "resource_id",
    actions: ["trash-alt"],
}
export const ResourceList=[
    {
        resource_id:1,
        color:'#FF0000',
        resource_name:'Resource 1',
        technology:'I.t'

    },
    {
        resource_id:2,
        color:'#FF0000',
        resource_name:'abcdefghijklmnopqrstuvwxyz abcdefghijklmnopqrstuvwxyz 2',
        technology:'abcdefghijklmnopqrstuvwxyz abcdefghijklmnopqrstuvwxyz z'

    },
    {
        resource_id:3,
        color:'#FF0000',
        resource_name:'Resource 2',
        technology:'abcdefghijklmnopqrstuvwxyz ab'

    },
    {
        resource_id:2,
        color:'#FF0000',
        resource_name:'abcdefghijklmnopqrstuvwxyz abcdefghijklmnopqrstuvwxyz 2',
        technology:'abcdefghijklmnopqrstuvwxyz abcdefghijklmnopqrstuvwxyz z'

    },
    {
        resource_id:3,
        color:'#FF0000',
        resource_name:'Resource 3',
        technology:'abcdefghijklmnopqrstuvwxyz ab'

    },
   
    {
        resource_id:3,
        color:'#FF0000',
        resource_name:'Resource 4',
        technology:'abcdefghijklmnopqrstuvwxyz ab'

    },
   
    {
        resource_id:3,
        color:'#FF0000',
        resource_name:'Resource 5',
        technology:'abcdefghijklmnopqrstuvwxyz ab'

    },
   
    {
        resource_id:3,
        color:'#FF0000',
        resource_name:'Resource 6',
        technology:'abcdefghijklmnopqrstuvwxyz ab'

    }
]

export const ResourceDetails={
    resource_id:null,
    resource_name:null,
    technology:null,
    color:"#000000",
    occupancy:null
}
