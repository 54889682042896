import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { MDBRadio } from 'mdb-react-ui-kit'
import DateField from '../SharedComponents/DateField/DateField'
import SearchSelectField from '../SharedComponents/SearchSelectField'
import SelectField from '../SharedComponents/SelectField/SelectField'
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField'
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel'
import BasicButton from '../SharedComponents/BasicButton/BasicButton'
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox'
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea'
import Radio from '@material-ui/core/Radio';
import { ResourcesData } from '../Groups/Config'
import { ScheduleMeetingData } from './Config'
import MessageModal from '../SharedComponents/MessageModal';
import moment from 'moment'
import "./ScheduleMeeting.scss"
import SchedulerService from "../../services/service"
import * as Constants from '../../constants/constants'
import { validateNumbersOnly } from '../../Common/Helper'
import { CircularProgress } from '@material-ui/core';
import { withUserContext } from '../../contexts/UserContext'
import Loader from '../../Common/Loader/Loader'

let meetingDuration = null;
class ScheduleMeeting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scheduleMeeting: ScheduleMeetingData,
            resourceData: [],
            showLoader: false,
            postInitiated: false,
            timeDropdown: [],
            invalidTime: false,
            invalidTimeMessage: 'Invalid from-to Time',
            isWeekend: false,
            interValRange: 1,
            minTime: null,
            maxTime: null,
            showConfirmTypePopup: false,
            showDeleteSuccess: false,
            showDeleteConfirmationPopup: false,
            meetingDurationState: null
        }
    }
    componentDidMount() {
        this.getStaticData();
        this.getResourceData();


    }
    getStaticData() {
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + "/staticData?staticTable=lu_timing", this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response?.data?.error) {
                    return;
                }
                this.setState({ minTime: response?.data?.[0]?.name, maxTime: response?.data?.[1]?.name }, () => {
                    this.generateTimeDropdownData();
                });


            }, err => {
                console.log("err", err)
            })
    }
    getResourceData() {
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + "/resource?resourceId=null", this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                console.log(response)
                let data = response.data;
                let formattedList = data?.map(item => ({ value: item.resource_id, text: item.resource_name }))
                this.setState({ resourceData: formattedList })
                if (this.props?.mode === "Edit") {
                    this.getMeetingData()
                }
            }, err => {
                console.log(err)
            })

    }
    getMeetingData() {
        this.setState({ showLoader: true })
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl + "/scheduleMeeting?meeting_id=" + this.props?.id + "&meeting_days_id=" + this.props?.meetingDaysId, this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                console.log(response)
                this.setState({ showLoader: false })
                if (response.data?.error) {
                    return;
                }
                let data = response?.data?.[0];
                data?.start_time ? this.calculateMeetingDuration(data?.start_time, data?.end_time) : null;
                // data.date= moment(data?.date).utc().format("YYYY/MM/DD"),
                data.date = moment(data?.date).utc().format("YYYY-MM-DD"),
                    data.start_time = moment(data?.start_time, 'HH:mm:ss').format("hh:mmA"),
                    data.end_time = moment(data?.end_time, 'HH:mm:ss').format("hh:mmA"),
                    data.resource_id = { value: data?.resource_id, text: data?.resource_name },
                    data.is_recurring = data?.is_recurring === 0 ? false : true;
                data.after_occurrence = data?.recurrence_range === "OCCURRENCE" ? data?.recurrence_range_value : null;
                let rangeVal = data?.recurrence_interval === 'DAILY' ? 1 : (data?.recurrence_interval === "WEEKLY" || data?.recurrence_interval === 'BI-WEEKLY') ? 2 : data?.recurrence_interval === 'MONTHLY' ? 3 : data?.recurrence_interval === "YEARLY" ? 5 : null;
                this.setState({ scheduleMeeting: data, interValRange: rangeVal })
            }, err => {
                console.log(err)
                this.setState({ showLoader: false })
            })
    }

    calculateMeetingDuration = (startTime, endTime) => {
        meetingDuration = moment(endTime, 'hh:mmA').diff(moment(startTime, 'hh:mmA'), 'minutes')
        this.setState({ meetingDurationState: meetingDuration })
    }

    handleChange = (field, value) => {
        let newEndTime, newStartTime;
                
        if (this.props?.mode === "Edit" && field === 'start_time' && moment(value, 'hh:mmA').isBefore(moment('11:00PM','hh:mmA'))) {             
            newEndTime = moment(value, 'hh:mmA').add(this.state.meetingDurationState, 'minutes').format('hh:mmA')                        
            console.log("Start and End Time", newStartTime, newEndTime);        
            this.setState(prevState => ({ scheduleMeeting: {...prevState.scheduleMeeting, 'end_time': newEndTime } }))
        }
        else if (field === 'start_time' && !this.state?.scheduleMeeting?.end_time && moment(value, 'hh:mmA').isBefore(moment('11:00PM','hh:mmA'))) {          
            newEndTime = moment(value, 'hh:mmA').add(60, 'minutes').format('hh:mmA')            
            console.log("Start and End Time", newStartTime, newEndTime); 
            this.setState(prevState => ({ scheduleMeeting: {...prevState.scheduleMeeting, 'end_time': newEndTime } }))          
        }
        let rangeVal = null;
        if (field === "recurrence_interval") {

            rangeVal = value === 'DAILY' ? 1 : (value === "WEEKLY" || value === 'BI-WEEKLY') ? 2 : value === 'MONTHLY' ? 3 : value === "YEARLY" ? 5 : null;
        }
        this.setState(prevState => ({
            scheduleMeeting: {
                ...prevState.scheduleMeeting,
                [field]: value || null               

            },
            ... (field === "recurrence_interval") && { interValRange: rangeVal }
        }))
    }
    generateTimeDropdownData() {
        let x = 15; //minutes interval
        let times = []; // time array

        let time = moment(this.state?.minTime, 'hh:mmA');
        let startTime = moment(this.state?.minTime, 'hh:mmA');
        let endTime = moment(this.state?.maxTime, 'hh:mmA');
        while (time.isSameOrBefore(endTime)) {
            let formatted = time.format("hh:mmA")
            if (formatted === "12:00AM") {
                formatted = moment(formatted, "hh:mmA").subtract(1, "minutes").format("hh:mmA")
            }
            times.push({ value: formatted, label: formatted });
            time.add(x, 'minute')
        }

        this.setState({ timeDropdown: times })
    }
    validateTime(from, to) {
        // console.log(moment(to, 'hh:mmA').format("hh:mmA"));
        // if(moment(to, 'hh:mmA').format("hh:mmA")== '12:00AM'){
        //     to = moment(to, 'hh:mmA').subtract(1,'minutes').format("hh:mmA")
        //     console.log(to);
        // }
        let valid = moment(from, 'hh:mmA').isBefore(moment(to, 'hh:mmA'))
        if (!valid) {
            this.setState({ invalidTime: true })
        }
        return valid;
    }
    validateBeforSubmit(postData) {
        this.setState({ invalidTime: false, isWeekend: false })
        let isWeekend = moment(postData?.date).day()
        if (isWeekend == 0 || isWeekend == 6) {
            this.setState({ isWeekend: true })
        }
        return postData?.date && (isWeekend != 0 && isWeekend != 6) &&
            postData?.start_time && postData?.end_time &&
            this.validateTime(postData?.start_time, postData?.end_time) &&
            postData?.subject &&
            postData?.resource_id &&
            (this.state?.scheduleMeeting?.recurrence_range === "OCCURRENCE" ? (this.state?.scheduleMeeting?.after_occurrence ? true : false) : true)

    }
    formResponse(data) {
        data.start_time = moment(data?.start_time, "hh:mmA").format("HH:mm:ss");
        data.end_time = moment(data?.end_time, "hh:mmA").format("HH:mm:ss");
        data.resource_name = data?.resource_id?.text
        data.resource_id = data?.resource_id?.value
        let daysInMonths = moment(data?.date).daysInMonth();
        data.recurrence_interval_value = data?.recurrence_interval === "DAILY" ? 1 : data?.recurrence_interval === "WEEKLY" ? 7 : data?.recurrence_interval === "BI-WEEKLY" ? 14 : data?.recurrence_interval === "MONTHLY" ? daysInMonths : data?.recurrence_interval === "YEARLY" ? 365 : 0;
        data.recurrence_range_value = data?.recurrence_range === "YEAR" ? this.state?.interValRange : parseInt(this.state?.scheduleMeeting?.after_occurrence);
        data.is_recurring = data.is_recurring === true ? 1 : 0;
        if (this.props?.mode === "Edit" && data?.is_recurring === 1) {
            // data.schedule_meeting_id = this?.props?.editType === "SINGLE" ? null : data?.schedule_meeting_id;
            data.schedule_meeting_days_id = this?.props?.editType === "SINGLE" ? data?.schedule_meeting_days_id : null;
        }
        return data;
    }
    postMeeting() {
        this.setState({ postInitiated: true, showLoader: true })
        let postData = { ...this.state?.scheduleMeeting }
        if (!this.validateBeforSubmit(postData)) {
            this.setState({ showLoader: false })
            return;
        }
        postData = this.formResponse(postData)
        // post API        
        SchedulerService.postDataParams(`${Constants.SchedulerServiceBaseUrl}/scheduleMeeting`, postData, this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                this.setState({ postInitiated: false, showLoader: false });
                if (response?.data?.[0]?.error_code || response?.data?.[0]?.error) {
                    this.props.fieldChanged(true, "fail", response?.data?.[0]?.error_code ? response?.data?.[0]?.error_message : undefined);
                    return;
                }
                // console.log(response);
                this.props?.setCallRefresh("Meeting", true, postData?.date)
                this.props.fieldChanged(true, "success");
                // this.props?.toggleRefreshPage("Meeting",postData?.date);
                // this.props?.toggleRefreshPage("Resource");

            }, (err) => {
                this.props.fieldChanged(true, "fail");
                this.setState({ postInitiated: false, showLoader: false });
            })
    }
    onDateChange(val) {
        if (val) {
            let isWeekend = moment(val).day()

            if (isWeekend == 0 || isWeekend == 6) {
                this.handleChange('date', null)
                this.setState({ isWeekend: true })
                return;
            }
            this.setState({ isWeekend: false })
            this.handleChange('date', val)
        } else {
            this.setState({ isWeekend: false })
            this.handleChange('date', null)
        }
    }
    deleteEvent() {
        this.setState({ showLoader: true });
        let postData = {
            schedule_meeting_id: this.props?.editType === 'SERIES' ? this.state?.scheduleMeeting?.schedule_meeting_id : null,
            schedule_meeting_days_id: this.props?.editType === 'SERIES' ? null : this.state?.scheduleMeeting?.schedule_meeting_days_id,
            is_occurence_delete: this.props?.editType === 'SERIES' ? 0 : 1
        }
        SchedulerService.postDataParams(`${Constants.SchedulerServiceBaseUrl}/scheduleMeetingDelete`, postData, this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                this.setState({ showLoader: false, showDeleteConfirmationPopup: false, showConfirmTypePopup: false, modalType: null });
                if (response?.data?.error_code || response.data.error) {
                    this.props?.fieldChanged(true, "fail", response?.data?.error_code ? response?.data?.error_message : undefined);
                    return;
                }
                // console.log(response);
                this.setState({ showDeleteSuccess: true, showConfirmTypePopup: true })
                // this.props?.fieldChanged(true, "success");

            }, err => {
                this.setState({ showLoader: false, showDeleteConfirmationPopup: false, showConfirmTypePopup: false, showDeleteSuccess: false });
                this.props?.fieldChanged(true, "fail");
                console.log('err', err)
            })
    }
    onConfirm() {
        if (this.state?.showDeleteConfirmationPopup) {
            this.deleteEvent();
        }
        else {
            this.onCancel();
            this.props?.onClose();
            this.props?.toggleRefreshPage("Resource");
        }
    }
    onCancel() {
        this.setState({ showConfirmTypePopup: false, showDeleteConfirmationPopup: false, showDeleteSuccess: false })
    }
    render() {
        let intervalType = this.state?.scheduleMeeting?.recurrence_interval;
        let message = this.state?.showDeleteSuccess ? "Deleted Successfully" : "Are you sure you want to delete?";
        let title = this.state?.showDeleteSuccess ? "Success" : "Confirmation";
        return (

            <MDBContainer className='ScheduleMeeting mt-2'>
                <Loader isOpen={this.state.showLoader || false} text="Loading..." />
                <MessageModal
                    open={this.state.showConfirmTypePopup || false}
                    title={title}
                    message={message}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    hideCancel={this.state?.showDeleteSuccess || false}
                    onConfirm={() => this.onConfirm()}
                    handleClose={() => this.onCancel()}
                />
                <MDBRow>
                    <MDBCol md={12}>
                        <BasicTextField
                            label={'Subject'}
                            isMandatory={true}
                            value={this.state?.scheduleMeeting?.subject || null}
                            onChange={(e) => this.handleChange('subject', e.target.value)}
                            showMandatory={this.state.postInitiated || false}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={10}>
                        <BasicLabel text='Room' isMandatory={true} />
                        <SearchSelectField
                            width={'100%'}
                            id='selectRoom'
                            // disabled={this.props?.mode === "Edit"  ? true : false}
                            options={this.state?.resourceData}
                            value={this.state?.scheduleMeeting?.resource_id || null}
                            onChange={(e, value) => this.handleChange('resource_id', value)}
                            showMandatory={this.state.postInitiated || false}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4}>
                        <DateField
                            label={'Date'}
                            isMandatory={true}
                            value={this.state?.scheduleMeeting?.date || null}
                            onChange={(e) => this.onDateChange(e.target.value)}
                            showMandatory={this.state.postInitiated || false}
                            disabled={this.props?.mode === "Edit" &&  this.props?.editType === 'SERIES'  ? true : false}
                        />
                        {this.state?.isWeekend && <div className='errorText'>You cant schedule meeting for weekend</div>}
                    </MDBCol>
                    <MDBCol md={4}>
                        {/* <DateField
                            label={'Date1'}
                            isMandatory={true}
                            value={this.state?.scheduleMeeting?.date || null}
                            onChange={(e) => this.handleChange('date', e.target.value)}
                            showMandatory={this.state.postInitiated || false}
                        /> */}
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol md={8}>
                        <BasicLabel text='Time' isMandatory={true} />
                        <div className="flex">
                            <SelectField
                                id='timeFrom'
                                className={"timeFrom"}
                                options={this.state?.timeDropdown}
                                value={this.state?.scheduleMeeting?.start_time}
                                onChange={(e) => this.handleChange('start_time', e.target.value)}
                            />
                            <SelectField
                                id='timeto'
                                className={"timeto"}
                                options={this.state?.timeDropdown}
                                value={this.state?.scheduleMeeting?.end_time}
                                onChange={(e) => this.handleChange('end_time', e.target.value)}
                            />
                        </div>
                        {this.state?.postInitiated && (!this.state?.scheduleMeeting?.start_time || !this.state?.scheduleMeeting?.end_time || this.state?.invalidTime) && <div className="errorText">{this.state?.invalidTime ? this.state?.invalidTimeMessage : "Please complete this mandatory field"}</div>}
                    </MDBCol>
                </MDBRow>
                {/* className={(this.props?.mode === "Edit") ? 'disabled' : null } */}
                <MDBRow>
                    <MDBCol md={8}>
                        <BasicCheckbox
                            id="recurring"
                            label="Recurring"
                            disabled={(this.props?.mode === "Edit") ? true : false}
                            checked={this.state?.scheduleMeeting?.is_recurring || false}
                            onChange={(e) => this.handleChange('is_recurring', e.target?.checked)}
                        />
                    </MDBCol>
                </MDBRow>
                {this.state?.scheduleMeeting?.is_recurring &&
                    <MDBRow className={(this.props?.mode === "Edit" && this.props?.editType === "SINGLE") ? 'disabled' : null}>
                        {/* <div className='recurringGroup'>
                        
                    </div> */}
                        <MDBCol md={4}>
                            <span className='radioHeading'>Recurrence Interval</span>
                            <MDBRadio
                                checked={intervalType === "DAILY" || false}
                                onChange={(e) => this.handleChange("recurrence_interval", e.target?.value)}
                                value={"DAILY"}
                                label="Daily"
                                labelClass={"radioLabel"}
                                name="recurring-type"
                            />
                            <MDBRadio
                                checked={intervalType === "WEEKLY" || false}
                                onChange={(e) => this.handleChange("recurrence_interval", e.target?.value)}
                                value={"WEEKLY"}
                                label="Weekly"
                                labelClass={"radioLabel"}
                                name="recurring-type"
                            />
                            <MDBRadio
                                checked={intervalType === "BI-WEEKLY" || false}
                                onChange={(e) => this.handleChange("recurrence_interval", e.target?.value)}
                                value={"BI-WEEKLY"}
                                label="Bi-Weekly"
                                labelClass={"radioLabel"}
                                name="recurring-type"
                            />
                            <MDBRadio
                                checked={intervalType === "MONTHLY" || false}
                                onChange={(e) => this.handleChange("recurrence_interval", e.target?.value)}
                                value={"MONTHLY"}
                                label="Monthly"
                                labelClass={"radioLabel"}
                                name="recurring-type"
                            />
                            <MDBRadio
                                checked={intervalType === "YEARLY" || false}
                                onChange={(e) => this.handleChange("recurrence_interval", e.target?.value)}
                                value={"YEARLY"}
                                label="Yearly"
                                labelClass={"radioLabel"}
                                name="recurring-type"
                            />
                        </MDBCol>
                        <MDBCol md={5}>
                            <span className='radioHeading'>Recurrence Range</span>
                            <MDBRadio
                                checked={this.state?.scheduleMeeting?.recurrence_range === "YEAR" || false}
                                onChange={(e) => this.handleChange('recurrence_range', e.target?.value)}
                                value={`YEAR`}
                                name="recurring-after"
                                labelClass={"radioLabel"}
                                label={`End After ${this.state?.interValRange} years`}
                            />
                            <div className='textfieldRadioWrapper'>

                                <MDBRadio
                                    checked={this.state?.scheduleMeeting?.recurrence_range === "OCCURRENCE" || false}
                                    onChange={(e) => this.handleChange('recurrence_range', e.target?.value)}
                                    value={'OCCURRENCE'}
                                    className='textfieldRadio'
                                    name="recurring-after"
                                    labelClass={"radioLabel"}
                                    label={`End After`}
                                />
                                <BasicTextField
                                    className='radioInput'
                                    isMandatory={true}
                                    // showMandatory={this.state?.scheduleMeeting?.recurrence_range === "OCCURRENCE" && this.state.postInitiated}
                                    value={this.state?.scheduleMeeting?.after_occurrence}
                                    onChange={(e) => validateNumbersOnly(e.target.value) ? this.handleChange('after_occurrence', e.target?.value) : null}
                                />
                                <label className='radioLabel'>Occurrence</label>
                            </div>
                            {this.state?.postInitiated && (this.state?.scheduleMeeting?.recurrence_range === "OCCURRENCE" && !this.state?.scheduleMeeting?.after_occurrence) && <div className="errorText">{!this.state?.scheduleMeeting?.after_occurrence ? "Please complete this mandatory field" : null}</div>}
                        </MDBCol>

                    </MDBRow>}
                <MDBRow>
                    <BasicTextArea
                        label="Notes"
                        value={this.state?.scheduleMeeting?.notes}
                        onChange={(e) => this.handleChange('notes', e.target.value)}
                    />
                </MDBRow>

                <div className="modalFooterBtn flex">
                    <BasicButton
                        variant='outlined'
                        text="Cancel"
                        type="inline"
                        onClick={this.props?.onClose}


                    />
                    {this.props?.mode == "Edit" &&
                        <BasicButton
                            // variant='outlined'
                            text="Delete"
                            type="inline"
                            onClick={() => this.setState({ showConfirmTypePopup: true, showDeleteConfirmationPopup: true })}
                        />
                    }
                    <BasicButton
                        text={this.state?.showLoader ? <CircularProgress color="inherit" size={20} /> : this.props?.mode == "Edit" ? "Reschedule" : "Schedule"}
                        type="inline"
                        onClick={this.postMeeting.bind(this)}
                    />
                </div>
            </MDBContainer>
        )
    }
}
export default withUserContext(ScheduleMeeting)
