import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export default class AccessDenied extends Component {
  render() {
    return (
      <div>
          <h6>Sorry! You are not allowed to view this page.</h6>
          {this.props?.showRedirect ? <Link to="/">Go Back to Dashboard </Link> : null}
      </div>
    )
  }
}
