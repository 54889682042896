import React,{useState,useRef,useEffect,useCallback} from "react"
import moment from "moment"
import { MDBBtn,MDBIcon,MDBRow,MDBCol,MDBRange } from "mdb-react-ui-kit";
import SelectField from "../../components/SharedComponents/SelectField/SelectField"
import SelectFieldHeader from "../SharedComponents/SelectFieldHeader/SelectFieldHeader";
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton'
import DateField from '../../components/SharedComponents/DateField/DateField'
// import Slider from '@material-ui/core/Slider';
import InputLabel from '@material-ui/core/InputLabel';
import {DaySelect,TimeSlot,Availability} from './config'
import './Calendar.scss'
import { useInsertionEffect } from "react";
import {debounce,validateNumbersOnly} from '../../Common/Helper'
import sizeLimit from "../../Common/SizeLimits.json"
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";

const CalendarHeader = (props) => {
    
    let {calendarRef, employeeNames,} = props;
    const [timeSlotOptions,setTimeSlotOptions] = useState(props?.timeSlotData)
            const [showSlider,setShowSlider] = useState(false);
    const [title, settitle] = useState(new moment().format("MMMM DD, YYYY"))
    const [filterObj,setFilterObj] = useState({startDate:moment().format("YYYY-MM-DD"),
                                                endDate:moment().format("YYYY-MM-DD"),
                                                group:null,
                                                avaialability:null,
                                                occupancyMin:null,
                                                occupancyMax:null,
                                            });
    const [dateField,setDateField] = useState(null);                                        
    const [viewType,setViewType] = useState('day');
    const [slot,setSlot] = useState(60);
    const inputeDate = useRef();
    const firstUpdate = useRef(true);
    const [viewMyMeetings, setViewMyMeetings] = useState(false);
    useEffect(()=>{
        props.changeDate(gotoDynamiceDate)
       
    },[])
    useEffect(()=>{     
        setTimeSlotOptions(props?.timeSlotData);
    },[props?.timeSlotData])
    const gotoDynamiceDate=()=>{
        onChangeDate(true);
        settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
    }
    useEffect(()=>{       
        onChangeDate();
    },[viewType])
    const delayFilterChange = useCallback(
        debounce((filterObj,viewMyMeetings) => {
          console.log('debounce',filterObj);
        //     if(firstUpdate?.current){
        //     firstUpdate.current = false
        //     return;
        // }
        // console.log(props)
        let {startDate,endDate,group,avaialability,occupancyMin,occupancyMax} = filterObj
        group = group === "" ? null : group?.join(',');
        avaialability = avaialability === ""? null : avaialability;
        occupancyMin = occupancyMin === ""? null : occupancyMin;
        occupancyMax = occupancyMax === ""? null : occupancyMax;
        props?.onChangeHandler(startDate,endDate,group,occupancyMin,occupancyMax,avaialability,viewMyMeetings)
        }, 1000),
        []
    );
    
    useEffect(()=>{      
        delayFilterChange(filterObj,viewMyMeetings)
    },[filterObj])
    const onChangeFilterObj=(type,val)=>{        
        setFilterObj({...filterObj,[type]:val})
    }
    const nextHandle = () => {
        calendarRef.current._calendarApi.next()      
        setDateField(null); 
        settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
        onChangeDate();
    }
    const prevHandle = () => {
        calendarRef.current._calendarApi.prev()
        setDateField(null);
        settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
        onChangeDate();
    }
    const onChangeDate = (isResetFilter)=> {
       
        console.log(calendarRef.current._calendarApi)
        console.log(calendarRef.current._calendarApi?.getDate())
        let startDate,endDate;
        let type = calendarRef.current._calendarApi.view.type;
        if(type === "resourceTimelineDay" || type === "resourceTimeGridDay"){
            startDate = moment(calendarRef.current._calendarApi?.getDate()).format("YYYY-MM-DD");
            endDate =  moment(calendarRef.current._calendarApi?.getDate()).format("YYYY-MM-DD");
        }
        else{
            startDate = moment(calendarRef.current._calendarApi?.view?.activeStart).format("YYYY-MM-DD");
            endDate =  moment(calendarRef.current._calendarApi?.view?.activeEnd).format("YYYY-MM-DD");
        }
        if(isResetFilter){
            setFilterObj({startDate,endDate,group:null,avaialability:null,occupancyMin:null,occupancyMax:null})
        }else{
            setFilterObj({...filterObj,startDate,endDate})
        }
    }
    const dateHandle=(e)=>{
        let date = e.target.value;
        let type = calendarRef.current._calendarApi.view.type;
        // console.log(date);
        // console.log(calendarRef);
        if(date){
            if(type == "resourceTimelineDay" || "resourceTimeGridDay"){
                calendarRef.current._calendarApi.gotoDate(date)               
            }else{
                let startOfWeek = moment(date).startOf('week').toDate();
                let endOfWeek   = moment(date).endOf('week').toDate();
                calendarRef.current._calendarApi.gotoDate(startOfWeek,endOfWeek)
            }
            setDateField(date);
            onChangeDate();
            settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
        }
        else{
            setDateField(null);
        }
       
    }
    const todayHandle = () => {
        calendarRef.current._calendarApi.today()
        settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
    }
    const dayHandle = () => {
        if(props?.type === 'timegrid'){
            calendarRef.current._calendarApi.changeView("resourceTimeGridDay")
        }else{
            calendarRef.current._calendarApi.changeView("resourceTimelineDay")
        }
        settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
    }
    const weekHandle = () => {
        if(props?.type === 'timegrid'){
            calendarRef.current._calendarApi.changeView("resourceTimeGridWeek")
        }else{
            calendarRef.current._calendarApi.changeView("resourceTimelineWeek")
        }
        
        settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
    }
    const monthHandle = () => {
        if(props?.type === 'timegrid'){
            calendarRef.current._calendarApi.changeView("resourceTimeGrid")
        }else{
            calendarRef.current._calendarApi.changeView("resourceTimelineMonth")
        }
       
        settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
    }
    const viewChangeHandle = (type) => {
        console.log(calendarRef.current._calendarApi);
        // let viewName = props?.type === 'timegrid' ? type=== 'day' ? 'resourceTimeGridDay' : "resourceTimeGridWeek" : props?.type === 'timeline' ? type=== 'day' ? 'resourceTimelineDay' : "resourceTimelineWeek" : null;
       let viewName = type === "day" ? 'resourceTimelineDay' : 'resourceTimeGridWeek'
       props?.classNameForSubject(type)
       setViewType(type);
        if(viewName){
            calendarRef.current._calendarApi.changeView(viewName);
            settitle(calendarRef.current._calendarApi.currentDataManager.data.viewTitle)
            slotChange(60);
        }
        if(type === "day"){           
            setTimeSlotOptions(props?.timeSlotData);
        }else{
            let data = props?.timeSlotData.filter(obj=>obj?.value <= 60)
            setTimeSlotOptions(data);
        }
    
    }
    const slotChange = (minute) =>{       
        if(!minute){
            minute = 60;
        }
        let duration = moment.duration(parseInt(minute),'minutes').asMilliseconds();
        let min = moment.utc(duration).format("HH:mm:ss"); 
            // calendarRef.current._calendarApi.setOption('slotDuration',min);
            calendarRef.current._calendarApi.setOption('slotLabelInterval',min);
        setSlot(minute)
    }
    const onChangeOfMyMeetings = (value) => {
        value ? setViewMyMeetings(value) : setViewMyMeetings(false)
        //props?.getViewMyMeetingsFilter(value)
        props?.onChangeHandler(filterObj.startDate,filterObj.endDate,filterObj.group?.join(','), filterObj.occupancyMin,filterObj.occupancyMax,filterObj.avaialability,value)
    }
    return (
        <div className={props.className}>
        <MDBRow className="mb-2">
            <MDBCol md={2}>
            <SelectFieldHeader
                width={'70%'}
                options={DaySelect}
                onChange={(e)=>viewChangeHandle(e.target.value)}
                value={viewType}
            />
            </MDBCol>        
        </MDBRow>
       
        <div  className='calendarHeaderRow mb-3'>
            <div className="leftAlignedHeader">
            <div>
            <span id="title" className="title">{title}</span>
            </div>
            <div className="dateIcons">
            <div className="dateNext">                     
                <MDBBtn floating color="primary" className="btn-theme btnPrev text-white  wid-55" onClick={() => prevHandle()}>
                        <MDBIcon fas icon='angle-left'/>
                    </MDBBtn>            
                <MDBBtn floating className="btn-theme btnNext text-white wid-55" onClick={() => nextHandle()}>                        
                         <MDBIcon fas icon='angle-right'/>
                </MDBBtn>
                {/* <MDBBtn className="btn-theme text-white f-12 wid-55" onClick={() => dateHandle()}>date</MDBBtn> */}
            </div>
            <div className="dateInput">
                <input className="dateField" ref={inputeDate} value={dateField} onChange={(e)=>dateHandle(e)}  type="date"/>
            </div>
            </div>
            </div>
            <div className="rightAlignedHeader">
            <div className="chkColor">
                        <BasicCheckbox
                            label="View My Meetings"
                            className="chkColor"
                             id={"myMeetings"}
                            checked={viewMyMeetings}
                            onChange={(e) => onChangeOfMyMeetings(e.target.checked)}
                             />
               </div>     
            <div className="fc-toolbar-chunk">
                <SelectFieldHeader
                id={"selectTimeslot"}
                // label={'Time Slot'}
                 placeHolderText={'Timeslot'}
                 isFirstOptionDisabled={true}
                 options={timeSlotOptions || []} 
                 onChange={(e)=>slotChange(e.target.value)}
                 value={slot}
                />
                
            </div>
            <div className="fc-toolbar-chunk">
            <SelectFieldHeader
                // label={'Select Group'}
                id={"selectGroup"}
                // width={"150px"}
                placeHolderText={'Group'}
                multiple={true}
                isFirstOptionDisabled={true}
                options={props?.groupData || []} 
                value={filterObj?.group || []}
                onChange={(e,value)=>{onChangeFilterObj('group',e.target?.value)}}
                />
            </div>
            

            <div className="fc-toolbar-chunk occupancyWrapper">
            {/* <SelectFieldHeader
                // label={'Select Occupancy'}
                placeHolderText={'Occupancy'}
                options={props?.occupancyData || []} 
                value={filterObj?.occupancy}
                isFirstOptionDisabled={false}
                onChange={(e,value)=>{onChangeFilterObj('occupancy',e?.target?.value)}}
             /> */}
   
            <InputLabel className = {showSlider ? "slider-label-selected": "slider-label"}
            id="range-slider-lbl" htmlFor="range-slider" onClick = {()=>setShowSlider(!showSlider)} >
                    Occupancy &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MDBIcon fas icon={showSlider ? "chevron-down": "chevron-right"} ></MDBIcon>
         </InputLabel>
        {showSlider && 
        <div className="minmaxFields">
        <BasicTextField id="min"
            placeholder={"min"}
            value={filterObj?.occupancyMin}
            limit={sizeLimit.threeDigitNumber}
            onChange={(e)=>validateNumbersOnly(e.target.value) && e.target?.value?.length <=sizeLimit.threeDigitNumber  ? onChangeFilterObj('occupancyMin',e.target.value):null}
        />
        <BasicTextField id="max"
             placeholder={"max"}
             value={filterObj?.occupancyMax}
             limit={sizeLimit.threeDigitNumber}
             onChange={(e)=>validateNumbersOnly(e.target.value) && e.target?.value?.length <=sizeLimit.threeDigitNumber ? onChangeFilterObj('occupancyMax',e.target.value):null}
        />
        </div>
        // <MDBRange
        // value={filterObj?.occupancy || null}
        // min={0}
        // max={100}
        // step={1}
        // onChange={(e,value)=>{onChangeFilterObj('occupancy',e?.target?.value)}}
        // id='range-slider'
        // // label='Example range'
        // />
        }
    </div>
            <div className="fc-toolbar-chunk">
            <SelectFieldHeader
                // label={'Select Availability'}
                id={'Availability'}
                placeHolderText={'Availability'}
                options={props?.avaialabilityData || []} 
                value={filterObj?.avaialability}
                isFirstOptionDisabled={false}
                onChange={(e,value)=>{onChangeFilterObj('avaialability',e?.target?.value)}}            
                />
            </div>
           
            <div className="fc-toolbar-chunk scheduleMeetingWrap">
                {props?.canAddMeeting &&
                <div>
                    <BasicButton 
                    text='Schedule Meeting'
                    icon='calendar-alt'
                    onClick={() => props?.showScheduleMeetingPopup()}                    
                    />
                    
                </div>
                }

            </div>
            </div>
           

        </div>
         
        </div>
    )
}
export default CalendarHeader;