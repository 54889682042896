import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem,  MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBNavbarLink, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { fade, makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import logo from '../assets/logo.png';
import { useHistory } from "react-router-dom";
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import AsynchronousSearch from '../../src/components/SharedComponents/AsynchronousSerach/AsynchronousSearch'
import { withUserContext } from '../contexts/UserContext';
import MessageModal from '../components/SharedComponents/MessageModal';
import MainViewModalComponent from '../Common/MainViewModalLayout/Modal/Modal'
import PDF from '../assets/Scheduler - User Manual.pdf'



export default //withRouter(
    withUserContext(
        withOktaAuth(class HeaderContent extends React.Component {
            constructor(props) {
                super(props);
                this.logout = this.logout.bind(this);
                this.login = this.login.bind(this);
                this.state = {
                    confirmSaveChanges: false,
                    switch_tenant_id: 0,
                    switch_tenant_name: "",
                    isManageUser: false,
                    showAllResourcePopup: false,
                    showAddEditResoucePopup: false,
                    showAllGroupsPopup:false,
                    showAddEditGroupPopup:false,
                    resource_id: null,
                    group_id:null,
                    refreshPage: { "modal": '', "value": false },
                }
            }
            // toggleRefreshPage = (modalName) => {
            //     let refreshPage = {
            //         "modal": modalName,
            //         "value": !this.state.refreshPage?.value
            //     }
            //     this.setState({ refreshPage: refreshPage })
            // }
            async logout() {
                var len = localStorage.length;
                var keys = Object.keys(localStorage);
                for (var i = 0; i < len; i++) {
                    var key = keys[i];
                    if (key.indexOf("okta-") == -1)
                        localStorage.removeItem(key);
                }

                this.props.oktaAuth.signOut('/');
            }


            async login() {
                // Redirect to '/' after logout
                this.props.authService.login('/');
            }
            handleSideNavActive = (value, routePath) => {
                this.props.history.push(routePath, this.state);
            }

            switchTenant = (tenant_id, tenant_name) => {
                this.setState({ switch_tenant_id: tenant_id, switch_tenant_name: tenant_name, confirmSaveChanges: !this.state.confirmSaveChanges });
            }

            confirmCancelChanges = (isCancel) => {
                //if (isCancel) {
                this.props.userContext.switchTenant(this.state.switch_tenant_id);
                //}
                //else {
                this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                //}
            }
            componentWillReceiveProps(nextProps) {
                this.setState({
                    isManageUser: nextProps.userContext?.active_tenant?.permissions?.isManageUser
                });
            }
           
            functions = {
                handleShowAllResourcePopup: () => {
                    this.setState({ showAllResourcePopup: true })
                },              
                handleShowAddEditResoucePopup: (id=null) => {
                    this.setState({ showAddEditResoucePopup: true,resource_id:id })
                },
                handleShowAllGroupsPopup: ()=>{
                    this.setState({showAllGroupsPopup:true})
                },
                handleShowAddEditGroupPopup: (id=null) => {
                    this.setState({ showAddEditGroupPopup: true,group_id:id })
                },
               


            }

            render() {
                const bgColorBlue = { backgroundColor: '#0062AF' }
                const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
                const ptli = { paddingTop: '0.1rem' }
                let permissions = this?.props?.userContext?.active_tenant?.permissions;
                return (
                    <div id="headerContent">
                        {/* <Router fixed="top"> */}
                        <header  >
                            <div className="fixed-top">
                                <MDBNavbar bgColor="primary" dark expand="md" fixed sticky >
                                    <MDBContainer fluid>
                                        <MDBNavbarBrand className='mb-0 h1'>

                                            {/* <MDBNavbarLink tag={Link} active={false} className="nav-link-override" to="/" > */}
                                            <Link component={RouterLink} className="action-icon" to={'/'} >
                                                <img
                                                    src={logo}
                                                    alt='Logo' className="imageWidth"
                                                //onClick={this.handleSideNavActive.bind(this, 'LP', "/")}
                                                /></Link>

                                            {/* </MDBNavbarLink> */}
                                            <h1 className="app-title">
                                                SCHEDULER
                                            </h1>
                                        </MDBNavbarBrand>
                                        <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>                                            {/* <MDBNavbarItem className="d-none d-lg-block">
                                            <span style={adminTitle}>Signed in as: &nbsp;
                                            <b> {this.props.user_full_name} </b>
                                                <br />
                                                <i> {this.props.active_tenant_role ? this.props.active_tenant_role + ", " + this.props.active_tenant_name : ""} </i>
                                            </span>
                                        </MDBNavbarItem> */}                                        
                                            
                                            {permissions?.canViewResources&& 
                                            <MDBNavbarItem className="padding-hor-25 text-white" onClick={this.functions?.handleShowAllResourcePopup}>
                                                Manage Resources
                                            </MDBNavbarItem> }
                                            {permissions?.canViewGroups && 
                                            <MDBNavbarItem className="padding-hor-25 text-white" onClick={this.functions?.handleShowAllGroupsPopup}>
                                                Manage Groups
                                            </MDBNavbarItem> }
                                            {/* <MDBNavbarItem className="padding-hor-25 text-white" >
                                                <MDBIcon fas size='3x' icon="users" className="mr-2" />
                                            </MDBNavbarItem> */}
                                            <MDBNavbarItem className="padding-hor-25" >
                                                <MDBDropdown >
                                                    <MDBDropdownToggle tag="a" className="bgColorBlue shadow-none text-white" >

                                                        <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="text-end border border-1-quinary">
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}>
                                                            <MDBDropdownLink className="fw-bold1" active={false} to='#'>{this.props?.active_tenant?.role || null}</MDBDropdownLink >
                                                         </MDBDropdownItem>
                                                         <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}>
                                                            <MDBDropdownLink className="fw-bold1" active={false} to='#'>{this.props?.userContext?.user_profile?.user_name || null}</MDBDropdownLink >
                                                         </MDBDropdownItem>
                                                         <MDBDropdownItem>
                                                            <MDBDropdownLink className="fw-bold1" href={PDF} download="Scheduler User Manual.pdf">
                                                                User Manual
                                                            </MDBDropdownLink>
                                                        </MDBDropdownItem>
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}
                                                            disabled={!this.props.isLoggedIn} onClick={this.logout} >
                                                            <MDBDropdownLink className="fw-bold1" active={false} to='/'>Logout</MDBDropdownLink >
                                                        </MDBDropdownItem>
                                                        

                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavbarItem>

                                        </MDBNavbarNav>
                                    </MDBContainer>
                                </MDBNavbar>
                                <MessageModal
                                    open={this.state.confirmSaveChanges}
                                    title={"Unsaved Changes"}
                                    message={"Switching tenant will lose any unsaved changes. Do you want to proceed?"}
                                    primaryButtonText={"Yes"}
                                    secondaryButtonText={"No"}
                                    ononfirm={() => this.confirmCancelChanges()}
                                    handleClose={(e) => this.setState({ confirmSaveChanges: false })} />
                            </div>
                        </header>
                        {/* </Router> */}
                        <div className="modals">
                            {this.state.showAllResourcePopup &&
                                <MainViewModalComponent
                                    open={this.state.showAllResourcePopup}
                                    handleClose={() => this.setState({ showAllResourcePopup: false })}
                                    headerText={"Manage Resources"}
                                    mode={"Both"}
                                    refreshPage={this.props?.refreshPage}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    modalName={"Resources"}
                                    addCallback={(id)=>this.functions.handleShowAddEditResoucePopup(id)}
                                />
                            }
                            {this.state.showAddEditResoucePopup &&
                                <MainViewModalComponent
                                    open={this.state.showAddEditResoucePopup}
                                    handleClose={() => this.setState({ showAddEditResoucePopup: false })}
                                    headerText={"Resource"}
                                    mode={this.state?.resource_id ? "Edit":"New"}
                                    id={this.state?.resource_id}
                                    refreshPage={this.props?.refreshPage}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    modalName={"New Resource"}
                                // handleSubmit={this.handleAddEpisode.bind(this)}
                                />
                            }
                             {this.state.showAllGroupsPopup &&
                                <MainViewModalComponent
                                    open={this.state.showAllGroupsPopup}
                                    handleClose={() => this.setState({ showAllGroupsPopup: false })}
                                    headerText={"Manage Groups"}
                                    mode={"Both"}
                                    modalName={"Groups"}
                                    refreshPage={this.props.refreshPage}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    addCallback={(id)=>this.functions.handleShowAddEditGroupPopup(id)}

                                />
                            }
                            {this.state.showAddEditGroupPopup &&
                                <MainViewModalComponent
                                    open={this.state.showAddEditGroupPopup}
                                    handleClose={() => this.setState({ showAddEditGroupPopup: false })}
                                    headerText={"Group"}
                                    mode={this.state?.group_id ? "Edit":"New"}
                                    id={this.state?.group_id}
                                    refreshPage={this.props?.refreshPage}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    modalName={"New Group"}
                                // handleSubmit={this.handleAddEpisode.bind(this)}
                                />
                            }
                        </div>
                    </div>
                );
            }
        }
        )
    );