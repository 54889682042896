import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import BasicButton from './BasicButton/BasicButton';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        // position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid grey',
        borderRadius: '8px',
        boxShadow: theme.shadows[5]
    },
    label: {
        fontSize: '0.75rem'
    }
}));

export default function MessageModal(props) {
    const classes = useStyles();
    let hideCancel = props.hideCancel || false;
    let hideSubmit = (props?.primaryButtonText != "" ? true : false)
    return (
        <div className="BasicModalContainer">
            <Modal
                open={props?.open ? props?.open : false}
                // onClose={props?.handleClose}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <div className="MessageTitle">
                        <label>{props?.title}</label>
                    </div>
                    <div className="MessageContent">
                        {props?.message && (<><span>{props?.message}</span><br /></>)}
                        {props?.children && <>{props?.children}</>}
                        <div className="ButtonDiv">
                            {hideSubmit ? <BasicButton
                                variant="contained"
                                text={props?.primaryButtonText}
                                type="inline"
                                onClick={props?.onConfirm}
                                disabled={false} /> : null}
                            {
                                !hideCancel && <div className="FloatRight">
                                <BasicButton
                                    variant="outlined"
                                    type="inline"
                                    text={props?.secondaryButtonText}
                                    onClick={props?.handleClose}
                                    disabled={false} />
                            </div>
                            }
                            <br />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
