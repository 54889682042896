import React, { Component } from 'react'
import Calendar from '../Calendar/Calendar'
class LandingPage extends Component {
  render() {
    return (
      <>
      <Calendar {...this.props}/>
      </>
    )
  }
}
export default  LandingPage
