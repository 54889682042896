import React, { Component } from 'react'
import TableComponent from '../SharedComponents/Table'
import { GroupTableConfig, GroupList } from "./Config"
import BasicButton from '../SharedComponents/BasicButton/BasicButton'
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import './Groups.scss'
import { MDBContainer } from "mdbreact"
import { withUserContext } from '../../contexts/UserContext';
import SchedulerService from '../../services/service';
import * as Constants from '../../constants/constants';
import Loader from "../../Common/Loader/Loader"
import MessageModal from '../SharedComponents/MessageModal';
class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: GroupTableConfig,
      groupList: [],
      sortCount: 0,
      sortBy: 'Group Name',
      isSortByAsc: true,
      sortColname: "group_name",
      renderList: [],
      groupRefresh:false,
      postInitiated: false,
      getGroups:()=>this.getGroups(),
      showPageLoader:false,
      mode:"",
      deleteId:null,
      showConfirmation:false,
      type:"Delete",
      confirmTitle:"Confirmation",
      confirmMsg:`Are you sure you want to ${this.state?.type} ?`,
      isCustomMsg:false,
      customMsg:"Are you sure you want to Delete Group? All meetings associated with this Group Resources will be deleted"

    }
  }
  static getDerivedStateFromProps(props, state) {      
    if ((props.refreshPage?.value !== state.groupRefresh) && props.refreshPage?.modal === 'Group') {
        if (props.refreshPage?.value) {
            state.getGroups();
            props.toggleRefreshPage(props.refreshPage?.modal)
        }
        return {
          groupRefresh: props.refreshPage?.value || false
        }
    }    
    return null;
}
handleDeleteCallback(id){
  console.log(id);
  if(!this.state?.showConfirmation){
      this.setState({showConfirmation:true,deleteId:id,isCustomMsg:true})
      return
  }
  let removedObj =  this.state?.groupList.filter(item=>item?.group_id == id)?.[0];
  this.setState({ showPageLoader: true,mode:"REMOVING" });
  if(removedObj){
      removedObj = {...removedObj,is_delete:1}
      SchedulerService.postDataParams(`${Constants.SchedulerServiceBaseUrl}/group`, removedObj,this.props?.userContext?.active_tenant?.tenant_id)
  .then((response) => {
      this.setState({ showPageLoader: false,showConfirmation:false,deleteId:null });
      if(response?.data?.error_code || response.data.error){
          this.props.fieldChanged(true, "fail",response?.data?.error_code ? response?.data?.error_message : undefined);
          return;
      } 
      this.props.toggleRefreshPage("Group");
      this.getGroups();
  }, (err) => {
      this.props.fieldChanged(true, "fail");
      this.setState({ showPageLoader: false,showConfirmation:false,deleteId:null });
  })
  }
}
  hyperLinkNavigationCallback(dataItem, node) {
    console.log(dataItem, "dataItem")
    console.log(node, "node")    
    // if (this.props?.userContext?.active_tenant?.permissions?.canAddEditGroups && dataItem?.group_id) {
      this.props?.addCallback(dataItem?.group_id)
    // }
  }
  componentDidMount() {
    this.getGroups();
  }
  getGroups() {
    this.setState({ postInitiated: true });
    SchedulerService.getData(Constants.SchedulerServiceBaseUrl + "/group?groupId=null", this.props?.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        console.log(response)
        let data = response.data;
        let formattedData = JSON.parse(JSON.stringify(data)).map(o => {
          o.resources = o.resources.map(i => i.resource_name)?.join(" , ");
          o.canDelete= this.props?.userContext?.active_tenant?.permissions?.canDeleteGroups;
        
          return o;
        })
        let list = tableSortList("Name", this.getSortNode(this.state.sortBy), formattedData, this.state.isSortByAsc);
        this.setState({ renderList: list, groupList: data, postInitiated: false });
      }, err => {
        // this.props.fieldChanged(true, "fail");
        console.log(err)
        this.setState({ postInitiated: false });
      })
  }
  handleTableColumnClick = (column) => {

    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })

  }
  getSortNode = (col) => {
    switch (col) {
      case "Group Name": return "group_name";
    }
  }
  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
    }, () => {
      this.setState({
        sortBy: colName,
        renderList: tableSortList("Name", this.getSortNode(colName), this.state?.renderList, this.state.isSortByAsc)
      });
    })
  }
  render() {
    let message = this.state.showConfirmation ? this.state.isCustomMsg ? this.state?.customMsg : this.state?.confirmMsg : "";
    let comfirmModalTitle = this.state.confirmTitle;

    return (
      <>
      <MessageModal
            open={this.state.showConfirmation || false}
            title={comfirmModalTitle}
            message={message}
            primaryButtonText={"OK"}
            secondaryButtonText={"Cancel"}
            onConfirm={() => this.handleDeleteCallback(this.state?.deleteId)}
            handleClose={(e) => this.setState({ showConfirmation: false})} />
      <MDBContainer fluid className='GroupTable mt-2'>
      <Loader isOpen={this.state.showPageLoader || false}
                    text={this.state.mode === 'LOADING'? "Loading..." : "Removing..."} />
        <div className='tableParentWrapper'>
          <TableComponent
            isLoading={this.state?.postInitiated}
            list={this.state?.renderList || []}
            config={this.state.config}
            hyperLinkNavigationCallback={this.hyperLinkNavigationCallback.bind(this)}
            handleDelCallBack={this.handleDeleteCallback.bind(this)}
            sortCallback={this.handleTableColumnClick}
          />
        </div>
        <div className="modalFooterBtn">
          {this.props?.userContext?.active_tenant?.permissions?.canAddGroups &&
          <BasicButton
          variant='outlined'
          text="New Group"
          type="inline"
          onClick={()=>this.props?.addCallback(null)}
          icon={"plus-circle"}
          />
          }
          <BasicButton
            text="Close"
            type="inline"
            onClick={this.props?.onClose}
          />
        </div>
      </MDBContainer>
      </>
    )
  }
}

export default withUserContext(Groups)
