import React from 'react';
import { MDBIcon } from 'mdbreact';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './shared.scss';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});
const mandatoryWarning = "Please complete this mandatory field"
const SearchSelectField = (props) => {
    let error = (props?.showMandatory && !props?.value?.value) || false;
    return (
        props.multiple ?
            <div className="SearchSelectFieldContainer">
                <Autocomplete
                    id={props?.id} multiple limitTags={1}
                    disabled={props?.disabled ? props?.disabled : false}
                    options={props?.options ? props?.options : []}
                    value={props?.value ? props?.value : null}
                    getOptionLabel={(option) => option.text ? option.text : ''}
                    getOptionSelected={(option, value) => option.value === value.value}
                    placeholder={props?.placeholder || ""}
                    filterOptions={filterOptions}
                    style={{ width: props.width ? props.width : '200px' }}
                    popupIcon={(props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                    onChange={props?.onChange}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
                 {error && <div className="errorText">{(props?.showMandatory && props?.value?.length === 0) ? mandatoryWarning : ""}</div>}
            </div> :
            <div className="SearchSelectFieldContainer">
                <Autocomplete
                    id={props?.id}
                    disabled={props?.disabled ? props?.disabled : false}
                    options={props?.options ? props?.options : []}
                    value={props?.value ? props?.value : null}
                    getOptionLabel={(option) => option.text ? option.text : ''}
                    getOptionSelected={(option, value) => option.value === value.value}
                    placeholder={props?.placeholder || ""}
                    filterOptions={filterOptions}
                    style={{ width: props.width ? props.width : '200px' }}
                    popupIcon={(props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                    onChange={props?.onChange}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
                {error && <div className="errorText">{(props?.showMandatory && !props?.value?.value) ? mandatoryWarning : ""}</div>}
            </div>
    );
}

export default SearchSelectField;
