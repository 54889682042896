import React from 'react';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import SearchFieldWithAddValueContainer from '../../components/SharedComponents/SearchFieldWithAddValue';
import TableComponent from '../../components/SharedComponents/Table';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';

const selectOptions = [
    {
        text: "Value 1",
        value: 1
    },
    {
        text: "Value 2",
        value: 2
    },
    {
        text: "Value 3",
        value: 3
    }
]

const configObject = {
    headings: [{
        headingLabel: "Name",
        icon: "sort-alpha-down"
    },
    {
        headingLabel: "Age",
        icon: ""
    },
    {
        headingLabel: "Place",
        icon: ""
    },
    {
        headingLabel: "Actions",
        icon: ""
    }],
    dataNodes: ["name", "age", "place"],
    primaryKey: "id",
    actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};

const list = [
    {
        id: 1,
        name: "Abc",
        age: 27,
        place: "Tvm"
    },
    {
        id: 2,
        name: "Def",
        age: 22,
        place: "tvm"
    }
]
export default class Field4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchSelectValue: 1
        }
    }
    render() {
        return (
            <div>
                <div style={{ paddingBottom: '20px' }}>
                    <SearchSelectField id={"key26"}
                        value={this.state.searchSelectValue}
                        options={selectOptions}
                        onChange={(e, value) => this.setState({ searchSelectValue: value })}
                    />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <SearchSelectField id={"key27"}
                        value={this.state.searchSelectValue}
                        options={selectOptions}
                        disabled={true}
                        onChange={(e, value) => this.setState({ searchSelectValue: value })}
                    />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <SearchFieldWithAddValueContainer id={"key28"}
                        value={this.state.searchSelectValue}
                        options={selectOptions}
                        onChange={(value) => this.setState({ searchSelectValue: value })}
                    />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <SearchFieldWithAddValueContainer id={"key29"}
                        value={this.state.searchSelectValue}
                        options={selectOptions}
                        disabled={true}
                        onChange={(value) => this.setState({ searchSelectValue: value })}
                    />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <TableComponent
                        list={list ? list : []}
                        config={configObject}
                        isLoading={false}
                        sortCallback={() => alert("sort callback")}
                        editCallback={() => alert("edit callback")} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicButton
                        variant="contained"
                        text="Button"
                        onClick={() => alert("Button Clicked")}
                        icon={"plus-circle"} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicButton
                        variant="contained"
                        text="Button"
                        type="inline"
                        onClick={() => alert("Button Clicked")}
                        icon={"plus-circle"} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicButton
                        variant="outlined"
                        text="Button"
                        onClick={() => alert("Button Clicked")}
                        icon={"plus-circle"} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicButton
                        onClick={() => alert("Button Clicked")}
                        text="Button"
                        variant="contained" />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicButton
                        onClick={() => alert("Button Clicked")}
                        text="Button"
                        variant="outlined" />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicButton
                        variant="contained"
                        text="Button"
                        onClick={() => alert("Button Clicked")}
                        disabled={true}
                        icon={"plus-circle"} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <BasicButton
                        disabled={true}
                        text="Button"
                        onClick={() => alert("Button Clicked")}
                        variant="contained" />
                </div>
            </div>
        )
    }
}
