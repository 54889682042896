import React, { Component } from 'react'
import TableComponent from '../SharedComponents/Table'
import {ResourceTableConfig,ResourceList} from "./Config"
import BasicButton from '../SharedComponents/BasicButton/BasicButton'
import {  updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import './Resource.scss'
import {MDBContainer} from "mdbreact"
import { withUserContext } from '../../contexts/UserContext';
import SchedulerService from "../../services/service"
import * as Constants from '../../constants/constants'
import Loader from "../../Common/Loader/Loader"
import MessageModal from '../SharedComponents/MessageModal';

class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config:ResourceTableConfig,
            resourceList: [],
            sortCount:0,
            postInitiated:false,
            sortBy:'Room Name',
            isSortByAsc:true,
            sortColname:"resource_name",
            renderList:[],
            showPageLoader:false,
            mode:"",
            resourceRefresh:false,
            getResources:()=>this.getResources(),
            deleteId:null,
            showConfirmation:false,
            type:"Delete",
            confirmTitle:"Confirmation",
            confirmMsg:`Are you sure you want to ${this.state?.type} ?`,
            isCustomMsg:false,
            customMsg:"Are you sure you want to Delete Resource? All meetings associated with this will be deleted"
        }
    }
    static getDerivedStateFromProps(props, state) {      
        if ((props.refreshPage?.value !== state.resourceRefresh) && props.refreshPage?.modal === 'Resource') {
            if (props.refreshPage?.value) {
                state.getResources();
                props.toggleRefreshPage(props.refreshPage?.modal)
            }
            return {
                resourceRefresh: props.refreshPage?.value || false
            }
        }    
        return null;
    }
    handleDeleteCallback(id){
        console.log(id);
        if(!this.state?.showConfirmation){
            this.setState({showConfirmation:true,deleteId:id,isCustomMsg:true})
            return
        }
        let removedObj =  this.state?.resourceList.filter(item=>item?.resource_id == id)?.[0];
        this.setState({ showPageLoader: true,mode:"REMOVING" });
        if(removedObj){
            removedObj = {...removedObj,is_delete:1}
            SchedulerService.postDataParams(`${Constants.SchedulerServiceBaseUrl}/resource`, removedObj,this.props?.userContext?.active_tenant?.tenant_id)
        .then((response) => {
            this.setState({ showPageLoader: false,showConfirmation:false,deleteId:null });
            if(response?.data?.error_code || response.data.error){
                this.props.fieldChanged(true, "fail",response?.data?.error_code ? response?.data?.error_message : undefined);
                return;
            } 
            this.props?.toggleRefreshPage("Resource");
            this.getResources();
        }, (err) => {
            this.props.fieldChanged(true, "fail");
            this.setState({ showPageLoader: false,showConfirmation:false,deleteId:null });
        })
        }
    }
   
    hyperLinkNavigationCallback(dataItem,node){
        console.log(dataItem,"dataItem")
        console.log(node,"node")
        if(dataItem?.resource_id){           
            this.props?.addCallback(dataItem?.resource_id)
        }
    }
    componentDidMount(){
        this.getResources();
       
    }
    getResources(){
        this.setState({ postInitiated: true });
        SchedulerService.getData(Constants.SchedulerServiceBaseUrl +"/resource?resourceId=null",this.props?.userContext?.active_tenant?.tenant_id)
        .then((response)=>{
            console.log(response)
            let data = response.data;
            data?.map(o=> o.canDelete= this.props?.userContext?.active_tenant?.permissions?.canDeleteResources)
            let list = tableSortList("Name", this.getSortNode(this.state.sortBy) , data, this.state.isSortByAsc)
            this.setState({ renderList: list, resourceList:data,postInitiated: false});
         
        },err=>{
            // this.props.fieldChanged(true, "fail");s
            this.setState({ postInitiated: false });
        })
    }
    handleTableColumnClick = (column) => {
       
        if (column === this.state.sortBy) {
            this.setState({ sortCount:  this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
        
    }
    getSortNode = (col) => {
        switch (col) {
            case "Room Name": return "resource_name";
        }
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state?.resourceList, this.state.isSortByAsc)
            });
        })
    }

   
    render() {
        let message = this.state.showConfirmation ? this.state.isCustomMsg ? this.state?.customMsg : this.state?.confirmMsg : "";
     let comfirmModalTitle = this.state.confirmTitle;
        return (
            <>
            <MessageModal
            open={this.state.showConfirmation || false}
            title={comfirmModalTitle}
            message={message}
            primaryButtonText={"OK"}
            secondaryButtonText={"Cancel"}
            onConfirm={() => this.handleDeleteCallback(this.state?.deleteId)}
            handleClose={(e) => this.setState({ showConfirmation: false})} />
            <MDBContainer fluid className='ResourceTable mt-2'>
                 <Loader isOpen={this.state.showPageLoader || false}
                    text={this.state.mode === 'LOADING'? "Loading..." : "Removing..."} />
                <div className='tableParentWrapper'>
                <TableComponent 
                    isLoading = {this.state?.postInitiated}
                    list={this.state?.resourceList || []}
                    config={ResourceTableConfig}  
                    hyperLinkNavigationCallback={this.hyperLinkNavigationCallback.bind(this)}                 
                    handleDelCallBack={this.handleDeleteCallback.bind(this)}
                    sortCallback={this.handleTableColumnClick}
                />
                </div>
                <div className="modalFooterBtn">
                {this.props?.userContext?.active_tenant?.permissions?.canAddResources &&
                    <BasicButton
                        variant='outlined'
                        text="New Resource"
                        type="inline"
                        onClick={()=>this.props?.addCallback(null)}
                        icon={"plus-circle"}
                    />
                }

                     <BasicButton                       
                        text="Close"
                        type="inline"
                        onClick={this.props?.onClose}
                    />
                </div>
            </MDBContainer>
            </>
        )
    }
}
export default  withUserContext(Resources)
