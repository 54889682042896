import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import TestBasicComp from '../pages/TestBasicComp/TestBasicComp';
import UserRoleManagement from '../components/UserManagement/UserManagement';
import LandingPage from '../components/LandingPage/LandingPage';
import AccessDenied from '../components/AccessDenied/AccessDenied';


export default withOktaAuth(class Routes extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    console.log("this props",this.props)
  }
  render() {

    return (

      <Switch>
           <Route exact={true} path='/userRoleManagement' render={(props) => this.props?.active_tenant?.permissions?.canAccessURM ? <UserRoleManagement {...props} /> : <AccessDenied showRedirect={true}/>} />

           {/* redirect to URM */}
          {this.props?.active_tenant?.permissions?.canAccessURM ? <Redirect exact={true} to='/userRoleManagement'  /> : null}
         
          <Route exact={true} path='/' render={(props) => this.props?.active_tenant?.permissions?.canViewMeetings ?
           <LandingPage {...props} refreshPage={this.props?.refreshPage} toggleRefreshPage={this.props?.toggleRefreshPage} {...this.props.functions}/> : <AccessDenied/> }/>
       
       {/* <Route exact={true} path='/test-basic-comp' render={(props) => <TestBasicComp {...props} />} /> */}
      </Switch>
    );
  }
}
);
